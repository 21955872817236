
@media only screen 
       and (max-width: 750px) {
    .container {
        grid-template-columns: [start] 1fr [end]; 
    }    
}

@media only screen 
       and (min-width: 751px)
       and (max-width: 1200px) {
    .container {
        grid-template-columns: [start] 1fr 1fr  [end];
    }
}

@media only screen 
       and (min-width: 1201px) {
    .container {
        grid-template-columns: [start] 1fr 1fr 1fr 1fr  [end];
    }
}


.container {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 10pt;
}

.contentColumn {
    grid-row-start: 2;
    grid-row-end: 3;    
    margin: 0;
    padding :0;
}    

.element {
    background-color: #ffffff;
    padding: 2ex;
    margin-bottom: 2px;
    margin-top: 4px;
}

.title {
    grid-column-start: start;
    grid-column-end: end;    
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: var(--main-bg-color);
    color: white;    
    padding-top: 1ex;
    padding-left: 2ex;
    padding-right: 2ex;
    margin: 0;
    text-shadow: 1px 1px 3px  #000000;
}

.title p {
    margin-top: 0.5ex;
    font-size: 80%;
}

.title h1{
    font-weight: normal;
}

.title h2{
    font-weight: 200;
}


.container h1 {
    margin: 0;  
    font-size: 140%;
    font-weight: normal;
}

.container h2 {
    margin: 0;  
    font-size: 100%;
    font-weight: normal;
    font-weight: 200;
}

.wrapper {
    padding: 4px;
    background-color: var(--main-bg-color);
}

.castka {
    font-weight: bold;
}

.splatnost {
    font-weight: bold;
}

.element-heading {
    display: flex;
    flex-direction: row;
}

.element-titles {
    flex-grow: 1;
}

.element-icons {
    flex-grow: 0;
}

.smlouva {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    border-bottom: thin gray solid;
}

.sml-column1 {
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    margin-left: 1ex;
}

.sml-column2 {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    margin-left: 1ex;
}

.sml-ikona {
    flex-grow:0;
}

.sml-emp {
    font-style: oblique;
}



